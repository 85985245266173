<script setup lang="ts">
import type {NavItem} from '@nuxt/content/dist/runtime/types'

const navigation = inject<Ref<NavItem[]>>('navigation', ref([]))

const links = [{
  label: 'Prijzen',
  to: '/prijzen'
}, {
  label: 'Leersysteem',
  to: '/leersysteem/getting-started'
}, {
  label: 'Vereniging',
  to: '/vereniging'
}, {
  label: 'Café/Bar',
  to: '/cafe-bar'
}, {
  label: 'Restaurant',
  to: '/restaurants'
}, {
  label: 'Slijterij',
  to: '/slijterij'
}, {
  label: 'Festival',
  to: '/festivals'
}]
</script>

<template>
  <UHeader :links="links">
    <template #logo>
      <NuxtImg src="logohorecawijs.png" width="175px" alt="horecawijs logo"/>
    </template>

    <template #right>
      <UButton
        label="Inloggen"
        color="purple"
        to="https://academie.horecawijs.nl/"
        target="_blank"
      />
    </template>

    <template #panel>
      <UNavigationTree
        :links="links"
        default-open
      />
    </template>
  </UHeader>
</template>
